import LandingPageLayout from "../components/landingPages/LandingPageLayout";
import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import outputPixelDensities from "../data/defaultPixelDensities";

const LandingNaturligVolum = () => {
    // const images = useStaticQuery(graphql`
    //     query {
    //       hero: file(relativePath: { eq: "landingpage/naturlig_volum/hero_naturlig_volum_rev5.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 1920, quality: 80, sizes: "(max-width: 900px) 50vw, 100vw") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //       heroDesktop: file(relativePath: { eq: "landingpage/naturlig_volum/hero_desktop_rev1.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 1920, quality: 80, sizes: "(max-width: 900px) 50vw, 100vw") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //       perkSpecialist: file(relativePath: { eq: "landingpage/perks/specialist_perk_rev2.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 450, quality: 80, sizes: "(max-width: 450px) 50vw, 300px") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //       perkFit: file(relativePath: { eq: "landingpage/perks/fit_rev1.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 450, quality: 80, sizes: "(max-width: 450px) 50vw, 300px") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //       perkLocation: file(relativePath: { eq: "landingpage/perks/location_rev2.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 300, quality: 80, sizes: "(max-width: 450px) 30vw, 300px") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //       img1: file(relativePath: { eq: "landingpage/naturlig_volum/gallery/1.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 450, quality: 80, sizes: "(max-width: 450px) 50vw, 300px") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //       img2: file(relativePath: { eq: "landingpage/naturlig_volum/gallery/2.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 450, quality: 80, sizes: "(max-width: 450px) 50vw, 300px") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //       img3: file(relativePath: { eq: "landingpage/naturlig_volum/gallery/3.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 450, quality: 80, sizes: "(max-width: 450px) 50vw, 300px") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //       img4: file(relativePath: { eq: "landingpage/naturlig_volum/gallery/4.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 450, quality: 80, sizes: "(max-width: 450px) 50vw, 300px") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //       img5: file(relativePath: { eq: "landingpage/naturlig_volum/gallery/5.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 450, quality: 80, sizes: "(max-width: 450px) 50vw, 300px") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //       img6: file(relativePath: { eq: "landingpage/naturlig_volum/gallery/6.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 450, quality: 80, sizes: "(max-width: 450px) 50vw, 300px") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //       img7: file(relativePath: { eq: "landingpage/naturlig_volum/gallery/7.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 450, quality: 80, sizes: "(max-width: 450px) 50vw, 300px") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //       img8: file(relativePath: { eq: "landingpage/naturlig_volum/gallery/8.jpg" }) {
    //         childImageSharp {
    //           fluid(maxWidth: 450, quality: 80, sizes: "(max-width: 450px) 50vw, 300px") {
    //             ...GatsbyImageSharpFluid_withWebp
    //           }
    //         }
    //       },
    //     }
    // `);

    const heroImage = <StaticImage // maxWidth: 1920, quality: 80, sizes: "(max-width: 900px) 50vw, 100vw"
        src={"../images/landingpage/naturlig_volum/hero_naturlig_volum_rev5.jpg"}
        alt={"Naturlig Volum Vippeextensions"}
        width={1920}
        sizes={"(max-width: 900px) 50vw, 100vw"}
        outputPixelDensities={outputPixelDensities}
    />
    const heroImageDesktop = <StaticImage
        src={"../images/landingpage/naturlig_volum/hero_desktop_rev1.jpg"}
        alt={"Naturlig Volum Vippeextensions"}
        width={1920}
        sizes={"(max-width: 900px) 50vw, 100vw"}
        outputPixelDensities={outputPixelDensities}
    />

    const gallery = [
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/1.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/2.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/3.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/4.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/5.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/6.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/7.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
        <StaticImage
            src={"../images/landingpage/naturlig_volum/gallery/8.jpg"}
            alt={"Øye med volumvipper"}
            width={450}
            sizes={"(max-width: 450px) 50vw, 300px"}
            outputPixelDensities={outputPixelDensities}
        />,
    ];

    const perks = [
        {
            image: <StaticImage
                src={"../images/landingpage/perks/specialist_perk_rev2.jpg"}
                alt={"Grunnlegger Benedikte Villmark"}
                sizes={"(max-width: 450px) 50vw, 300px"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            caption: "Utføres av spesialister"
        },
        {
            image: <StaticImage
                src={"../images/landingpage/perks/fit_rev1.jpg"}
                alt={"Øye med volumvipper"}
                sizes={"(max-width: 450px) 50vw, 300px"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            caption: "Vi tilpasser vippene til dine øyne"
        },
        {
            image: <StaticImage
                src={"../images/landingpage/perks/location_rev2.jpg"}
                alt={"Salongen på Grünerløkka"}
                sizes={"(max-width: 450px) 50vw, 300px"}
                width={450}
                outputPixelDensities={outputPixelDensities}
            />,
            caption: "I Oslo Sentrum og på Grünerløkka"
        }
    ];

    const services = [
        {name: "Nytt sett naturlig volum (2D-3D)", price: "1590,-"},
        {name: "Påfyll 90 minutter", price: "990,-"},
        {name: "Påfyll 60 minutter", price: "790,-"}
    ];

    const videoSrc = '/background_video/naturlig_volum_rev8.mp4';

    return(
        <LandingPageLayout
            title={"Naturlig Volum"}
            heroImage={heroImage}
            heroImageDesktop={heroImageDesktop}
            heroCaption={`
                Volumvipper som gir en fyldig, men naturlig, look.
            `}
            text={`
                 Volumvipper gir fylde. Volumvipper er ekstra tynne vippeextensions, 
                 men man setter flere falske vipper på én naturlig vipp. Desto flere hår vi fester i hver vipp, 
                 desto svartere og fyldigere blir det. Dersom du har normalt sterke vipper kan du velge fylden helt selv.
                 Når du kommer innom salongen kan vi selvfølgelig hjelpe deg med å velge. 
                 Vi kan god erfaring med å se hva slags vippeextensions som vil passe best, 
                 ut ifra hva hva slags look du ønsker (dramatisk/naturlig), og ut i fra dine naturlige vipper.
            `}
            gallery={gallery}
            perks={perks}
            services={services}
            readMorePath={"/vippeextensionsvarianter"}
            videoSrc={videoSrc}
        />
    )
};

export default LandingNaturligVolum;